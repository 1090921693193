(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("styled-components"), require("recompose"));
	else if(typeof define === 'function' && define.amd)
		define(["prop-types", "react", "styled-components", "recompose"], factory);
	else if(typeof exports === 'object')
		exports["@urbica/ui-kit"] = factory(require("prop-types"), require("react"), require("styled-components"), require("recompose"));
	else
		root["@urbica/ui-kit"] = factory(root["prop-types"], root["react"], root["styled-components"], root["recompose"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 