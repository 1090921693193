import styled from 'styled-components';

export default styled.div`
  flex: 1;
  font-size: 12px;
  color: #263238;
  padding-left: 5px;

  text-align: left;
`;

